import T_en from "../translations/en.json";
import T_fr from "../translations/fr.json";
import T_hu from "../translations/hu.json";

const TRANSLATIONS = {
	"en": T_en, "fr": T_fr, "hu": T_hu,
};

/**
 * Get text labels translations in given language
 *
 * @param {string} lang The language code (fr, en)
 * @returns {object} Translations in given language, with fallback to english
 * @private
 */
export function getTranslations(lang = "") {
	const myTr = JSON.parse(JSON.stringify(T_en));
	let preferedTr;
	
	// Lang exists -> send it
	if(TRANSLATIONS[lang] && lang !== "en") {
		preferedTr = TRANSLATIONS[lang];
	}

	// Look for primary lang
	if(lang.includes("-")) {
		const primaryLang = lang.split("-")[0];
		if(TRANSLATIONS[primaryLang]) { preferedTr = TRANSLATIONS[primaryLang]; }
	}

	if(lang.includes("_")) {
		const primaryLang = lang.split("_")[0];
		if(TRANSLATIONS[primaryLang]) { preferedTr = TRANSLATIONS[primaryLang]; }
	}

	// Merge labels to avoid missing ones
	if(preferedTr) {
		Object.entries(preferedTr).forEach(([k1, v1]) => {
			Object.assign(myTr[k1], v1);
		});
	}

	return myTr;
}