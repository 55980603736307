import "./Widgets.css";
import { PSV_ANIM_DURATION, PSV_ZOOM_DELTA, PIC_MAX_STAY_DURATION } from "../Viewer";
import {
	createPanel, createGroup, fa, fat, createButton, disableButton,
	createSearchBar, createExpandableButton, enableButton, enableCopyButton
} from "../utils/Widgets";
import { COLORS } from "../utils/Utils";
import SwitchBig from "../img/switch_big.svg";
import SwitchMini from "../img/switch_mini.svg";
import BackgroundAerial from "../img/bg_aerial.jpg";
import BackgroundStreets from "../img/bg_streets.jpg";
import { getGPSPrecision } from "../utils/Exif";

// Every single icon imported separately to reduce bundle size
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons/faShareNodes";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faPanorama } from "@fortawesome/free-solid-svg-icons/faPanorama";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faBackward } from "@fortawesome/free-solid-svg-icons/faBackward";
import { faForward } from "@fortawesome/free-solid-svg-icons/faForward";
import { faPause } from "@fortawesome/free-solid-svg-icons/faPause";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons/faSatelliteDish";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons/faEllipsisVertical";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faPalette } from "@fortawesome/free-solid-svg-icons/faPalette";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faPersonBiking } from "@fortawesome/free-solid-svg-icons/faPersonBiking";
import { faSliders } from "@fortawesome/free-solid-svg-icons/faSliders";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faEarthEurope } from "@fortawesome/free-solid-svg-icons/faEarthEurope";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import { faSquareRss } from "@fortawesome/free-solid-svg-icons/faSquareRss";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons/faCloudArrowDown";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";


/**
 * Handles all map/viewer buttons visible on UI.
 * Also handles switch between map and viewer, and responsiveness.
 * 
 * @private
 */
export default class Widgets {
	/**
	 * @param {Viewer} viewer The GeoVisio viewer
	 * @param {object} [options] Widgets options
	 * @param {string} [options.editIdUrl] Edit with iD URL
	 * @param {string} [options.mapAttribution] Override default map attribution
	 * @param {string|Element} [options.customWidget] A user-defined widget to add
	 */
	constructor(viewer, options = {}) {
		// Set default options
		if(options == null) { options = {}; }
		if(options.editIdUrl == null) { options.editIdUrl = "https://www.openstreetmap.org/edit"; }
		
		this._viewer = viewer;
		this._t = this._viewer._t;
		this._options = options;
		const hasMap = this._viewer.map !== undefined;

		// Create widgets "corners"
		this._corners = {};
		const components = hasMap ? ["main", "mini"] : ["main"];
		const cornerSpace = ["top", "bottom"];
		const corners = ["left", "middle", "right"];
		for(let cp of components) {
			for(let cs of cornerSpace) {
				const csDom = document.createElement("div");
				csDom.id = `gvs-corner-${cp}-${cs}`;
				csDom.classList.add("gvs-corner-space");

				for(let cn of corners) {
					const corner = document.createElement("div");
					corner.id = `${csDom.id}-${cn}`;
					corner.classList.add("gvs-corner");
					this._corners[`${cp}-${cs}-${cn}`] = corner;
					csDom.appendChild(corner);
				}

				if(cp == "main") { this._viewer.mainContainer.appendChild(csDom); }
				else if(cp == "mini") { this._viewer.miniContainer.appendChild(csDom); }
			}
		}

		this._initWidgetPlayer(hasMap);
		this._initWidgetLegend(hasMap, options?.mapAttribution);

		if(hasMap) {
			this._initWidgetMiniActions();
			this._initWidgetSearch();
			this._initWidgetFilters(
				this._viewer._api._endpoints.user_search !== null
				&& this._viewer._api._endpoints.user_tiles !== null
			);
			this._initWidgetMapLayers();
			this._listenMapFiltersChanges();
		}

		if(!this._viewer.isWidthSmall()) {
			this._initWidgetShare();
		}

		// Custom widget provided by user
		if(options.customWidget) {
			const corner = this._corners["main-bottom-right"];

			switch(typeof options.customWidget) {
			case "string":
				for(let e of new DOMParser().parseFromString(options.customWidget, "text/html").body.children) {
					corner.appendChild(e);
				}
				break;
			
			case "object":
				if(Array.isArray(options.customWidget)) {
					options.customWidget.forEach(e => corner.appendChild(e));
				}
				else {
					corner.appendChild(options.customWidget);
				}
				break;
			}
		}

		this._initWidgetZoom(hasMap);

		// Click outside of an open panel -> closes panels
		this._viewer.container.addEventListener("click", e => {
			let target = e.target;

			// Go to parent if clicked element is SVG
			// This avoids weird stuff on search bar icon
			//   which changes dynamically
			while(target && ["svg", "path"].includes(target.tagName)) {
				target = target.parentNode;
			}

			if(
				target
				&& !target.classList.contains("gvs-panel")
				&& !target.classList.contains("gvs-search-bar-result")
				&& !target.classList.contains("gvs-search-empty")
			) {
				for(const p of this._viewer.container.getElementsByClassName("gvs-panel")) {
					if(!p.contains(target)) {
						p.classList.add("gvs-hidden");
					}
				}

				// Also reduce eventual expandable search bars
				for(const p of this._viewer.container.getElementsByClassName("gvs-search-bar-reducable")) {
					if(!p.contains(target)) {
						p.resetSearch();
					}
				}
			}
		});
	}

	/**
	 * Ends all form of life in this object.
	 */
	destroy() {
		Object.values(this._corners).forEach(e => e.remove());
		delete this._corners;
		delete this._t;
		delete this._viewer;
	}

	/**
	 * Creates the zoom buttons group
	 * @param {boolean} hasMap True if map is enabled
	 * @private
	 */
	_initWidgetZoom(hasMap) {
		this._lastWantedZoom = this._viewer.psv.getZoomLevel();

		// Presentation
		const btnZoomIn = createButton("gvs-zoom-in", fa(faPlus), this._t.gvs.zoomIn);
		const btnZoomOut = createButton("gvs-zoom-out", fa(faMinus), this._t.gvs.zoomOut);
		createGroup("gvs-widget-zoom", "main-bottom-right", this, [btnZoomIn, btnZoomOut], ["gvs-group-vertical", "gvs-mobile-hidden", "gvs-print-hidden"]);

		// Events
		const zoomFct = (e, zoomIn) => {
			if(hasMap && this._viewer.isMapWide()) {
				if(zoomIn) { this._viewer.map.zoomIn({}, {originalEvent: e}); }
				else { this._viewer.map.zoomOut({}, {originalEvent: e}); }
			}
			else {
				if(this._viewer.lastPsvAnim) { this._viewer.lastPsvAnim.cancel(); }
				const goToZoom = zoomIn ?
					Math.min(100, this._lastWantedZoom + PSV_ZOOM_DELTA)
					: Math.max(0, this._lastWantedZoom - PSV_ZOOM_DELTA);
				this._viewer.lastPsvAnim = this._viewer.psv.animate({
					speed: PSV_ANIM_DURATION,
					zoom: goToZoom
				});
				this._lastWantedZoom = goToZoom;
			}
		};

		btnZoomIn.addEventListener("click", e => zoomFct(e, true));
		btnZoomOut.addEventListener("click", e => zoomFct(e, false));
	}

	/**
	 * Creates play/pause/next/prev picture buttons
	 * @param {boolean} hasMap True if map is enabled
	 * @private
	 */
	_initWidgetPlayer(hasMap) {
		// Presentation
		const btnPlayerPrev = createButton("gvs-player-prev", fa(faBackward), this._t.gvs.sequence_prev);
		const btnPlayerPlay = createButton("gvs-player-play");
		const btnPlayerNext = createButton("gvs-player-next", fa(faForward), this._t.gvs.sequence_next);
		const btnPlayerMore = createButton("gvs-player-more", fa(faEllipsisVertical), this._t.gvs.sequence_more, ["gvs-xs-hidden"]);

		// Panel for more options
		const pnlOpts = createPanel(this, btnPlayerMore, [], ["gvs-player-options", "gvs-input-btn"]);
		pnlOpts.innerHTML = `
			<div class="gvs-input-range" title="${this._t.gvs.sequence_speed}">
				${fat(faPersonBiking)}
				<input
					id="gvs-player-speed"
					type="range" name="speed"
					min="0" max="${PIC_MAX_STAY_DURATION - 100}"
					value="${PIC_MAX_STAY_DURATION - this._viewer.psv.getTransitionDuration()}"
					title="${this._t.gvs.sequence_speed}"
					style="width: 100%;" />
				${fat(faRocket)}
			</div>
			<button title="${this._t.gvs.contrast}" id="gvs-player-contrast">
				${fat(faLightbulb)}
			</button>
		`;

		// Group widget
		const grpPlayer = createGroup(
			"gvs-widget-player",
			!hasMap ? "main-top-left" : "main-top-middle",
			this,
			[btnPlayerPrev, btnPlayerPlay, btnPlayerNext].concat(this._viewer.isWidthSmall() ? [] : [pnlOpts, btnPlayerMore]),
			["gvs-group-horizontal", "gvs-only-psv", "gvs-print-hidden", this._viewer.psv.getPictureMetadata() ? "" : "gvs-hidden"]
		);

		// Toggle state of play button
		const toggleBtnPlay = (isPlaying) => {
			btnPlayerPlay.innerHTML = isPlaying ? fat(faPause) : fat(faPlay);
			btnPlayerPlay.title = isPlaying ? this._t.gvs.sequence_pause : this._t.gvs.sequence_play;
		};
		toggleBtnPlay(false);

		// Listening to viewer events
		this._viewer.addEventListener("sequence-playing", () => toggleBtnPlay(true));
		this._viewer.addEventListener("sequence-stopped", () => toggleBtnPlay(false));
		this._viewer.addEventListener("psv:picture-loaded", () => grpPlayer.classList.remove("gvs-hidden"), { once: true });
		this._viewer.addEventListener("psv:picture-loaded", () => {
			if(this._viewer.psv.getPictureMetadata()?.sequence?.prevPic != null) { enableButton(btnPlayerPrev); }
			else { disableButton(btnPlayerPrev); }

			if(this._viewer.psv.getPictureMetadata()?.sequence?.nextPic != null) {
				enableButton(btnPlayerNext);
				enableButton(btnPlayerPlay);
			}
			else {
				disableButton(btnPlayerNext);
				disableButton(btnPlayerPlay);
			}
		});

		if(!this._viewer.isWidthSmall()) {
			const btnPlayerSpeed = pnlOpts.children[0].children[1];

			this._viewer.addEventListener("psv:transition-duration-changed", e => {
				btnPlayerSpeed.value = PIC_MAX_STAY_DURATION - e.detail.value;
			});

			btnPlayerSpeed.addEventListener("change", e => {
				const newSpeed = PIC_MAX_STAY_DURATION - e.target.value;
				this._viewer.psv.setTransitionDuration(newSpeed);
			});
		}

		// Buttons events
		btnPlayerPrev.addEventListener("click", () => this._viewer.psv.goToPrevPicture());
		btnPlayerNext.addEventListener("click", () => this._viewer.psv.goToNextPicture());

		btnPlayerPlay.addEventListener("click", () => {
			if(this._viewer.isSequencePlaying()) {
				toggleBtnPlay(false);
				this._viewer.stopSequence();
			}
			else {
				toggleBtnPlay(true);
				this._viewer.playSequence();
			}
		});

		const btnPlayerContrast = document.getElementById("gvs-player-contrast");
		if(btnPlayerContrast) {
			btnPlayerContrast.addEventListener("click", () => {
				if(btnPlayerContrast.classList.contains("gvs-btn-active")) {
					btnPlayerContrast.classList.remove("gvs-btn-active");
					this._viewer.psv.setHigherContrast(false);
				}
				else {
					btnPlayerContrast.classList.add("gvs-btn-active");
					this._viewer.psv.setHigherContrast(true);
				}
			});
		}
	}

	/**
	 * Creates legend block
	 * @param {boolean} hasMap True if map is enabled
	 * @param {string} [mapAttribution] Override map attribution
	 * @private
	 */
	_initWidgetLegend(hasMap, mapAttribution) {
		// Presentation (main widget)
		const mainLegend = createGroup(
			"gvs-widget-legend",
			hasMap ? "main-bottom-right" : "main-bottom-left",
			this,
			[],
			["gvs-widget-bg"]
		);

		// Presentation (mini widget)
		let miniLegend;
		if(hasMap) {
			miniLegend = createGroup(
				"gvs-widget-mini-legend",
				"mini-bottom-right",
				this,
				[],
				["gvs-widget-bg", "gvs-only-mini", "gvs-mobile-hidden"]
			);
		}

		// Show/hide legend button (for small devices)
		let btnVisibLegend, toggleVisibLegend;
		if(this._viewer.isWidthSmall()) {
			btnVisibLegend = document.createElement("button");
			btnVisibLegend.id = "gvs-legend-toggle";
			btnVisibLegend.classList.add("gvs-btn", "gvs-widget-bg", "gvs-print-hidden");
			btnVisibLegend.appendChild(fa(faCircleInfo));
			toggleVisibLegend = () => {
				if(mainLegend.style.visibility === "hidden") {
					mainLegend.style.visibility = "visible";
					if(!hasMap) { toggleLegend(false); }
					else { toggleLegend(this._viewer.isMapWide()); }	
				}
				else {
					mainLegend.innerHTML = "";
					mainLegend.style.visibility = "hidden";
					mainLegend.appendChild(btnVisibLegend);
				}
			};
			btnVisibLegend.addEventListener("click", e => {
				e.stopPropagation();
				toggleVisibLegend();
			});
		}

		const toggleLegend = (focusOnMap) => {
			let mapLegend = mapAttribution || this._viewer.map?._attribution?._attribHTML || "";
			let picLegend = "<a href='https://gitlab.com/panoramax/clients/web-viewer/' target='_blank'>GeoVisio</a>";

			// Picture legend based on current picture metadata
			const picMeta = this._viewer.psv.getPictureMetadata()?.caption;
			let picMetaBtn;
			if(picMeta) {
				picLegend = "";
				if(picMeta.producer) {
					picLegend += `<span style="font-weight: bold">&copy; ${picMeta.producer}</span>`;
				}
				if(picMeta.date) {
					if(picMeta.producer) { picLegend += "&nbsp;-&nbsp;"; }
					picLegend += picMeta.date.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
				}

				// Button for metadata popup
				picMetaBtn = fa(faCircleQuestion);
				picMetaBtn.style.marginLeft = "5px";
			}

			// Put appropriate legend according to view focus
			if(focusOnMap) {
				mainLegend.innerHTML = mapLegend;
				mainLegend.style.cursor = null;
				mainLegend.onclick = null;
				miniLegend.innerHTML = picLegend;
			}
			else {
				mainLegend.innerHTML = picLegend;

				if(picMetaBtn) {
					mainLegend.appendChild(picMetaBtn);
					mainLegend.style.cursor = "pointer";
					mainLegend.onclick = this._showPictureMetadataPopup.bind(this);
				}
				else {
					mainLegend.style.cursor = null;
					mainLegend.onclick = null;
				}

				if(hasMap) { miniLegend.innerHTML = mapLegend; }
			}

			if(btnVisibLegend) { mainLegend.appendChild(btnVisibLegend); }
		};

		if(!btnVisibLegend) {
			if(!hasMap) { toggleLegend(false); }
			else { toggleLegend(this._viewer.isMapWide()); }
		}
		else {
			mainLegend.appendChild(btnVisibLegend);
			mainLegend.style.visibility = "hidden";
		}

		// Listening to viewer events
		this._viewer.addEventListener("focus-changed", e => toggleLegend(e.detail.focus == "map"));
		this._viewer.addEventListener("psv:picture-loaded", () => toggleLegend(hasMap && this._viewer.isMapWide()));
	}

	/**
	 * Displays current picture metadata in popup
	 * @private
	 */
	_showPictureMetadataPopup() {
		const picMeta = this._viewer.psv.getPictureMetadata();
		if(!picMeta) { throw new Error("No picture currently selected"); }

		// General
		let popupContent = `
			<h3>${fat(faCircleInfo)} ${this._t.gvs.metadata}</h3>
			<table class="gvs-table-light">
				<tr><th scope="row">${this._t.gvs.metadata_general_picid}</th>
					<td class="gvs-input-btn">
						<a
							href="${this._viewer._api.getPictureMetadataUrl(picMeta.id, picMeta?.sequence?.id)}"
							target="_blank"
							title="${this._t.gvs.metadata_general_picid_link}"
						>${picMeta.id}</a>
						<button data-copy="${picMeta.id}">${fat(faCopy)} ${this._t.gvs.copy}</button>
					</td>
				</tr>
				<tr><th scope="row">${this._t.gvs.metadata_general_seqid}</th>
					<td class="gvs-input-btn">
						<a
							href="${this._viewer._api.getSequenceMetadataUrl(picMeta?.sequence?.id)}"
							target="_blank"
							title="${this._t.gvs.metadata_general_seqid_link}"
						>${picMeta?.sequence?.id}</a>
						<button data-copy="${picMeta?.sequence?.id}">${fat(faCopy)} ${this._t.gvs.copy}</button>
					</td>
				</tr>
				<tr><th scope="row">${this._t.gvs.metadata_general_author}</th><td>${picMeta?.caption?.producer}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_general_license}</th><td>${picMeta?.caption?.license}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_general_date}</th>
					<td>${picMeta?.caption?.date?.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric", fractionalSecondDigits: 3, timeZoneName: "short" })}</td>
				</tr>
			</table>`;
		
		// Camera
		let focal = picMeta?.properties?.["pers:interior_orientation"]?.focal_length;
		if(focal) { focal += " mm"; }
		else { focal = "unknown"; }
		popupContent += `
			<h4>${fat(faCamera)} ${this._t.gvs.metadata_camera}</h4>
			<table class="gvs-table-light">
				<tr><th scope="row">${this._t.gvs.metadata_camera_make}</th><td>${picMeta?.properties?.["pers:interior_orientation"]?.camera_manufacturer}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_camera_model}</th><td>${picMeta?.properties?.["pers:interior_orientation"]?.camera_model}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_camera_type}</th><td>${picMeta?.horizontalFov === 360 ? this._t.gvs.picture_360 : this._t.gvs.picture_flat}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_camera_focal_length}</th><td>${focal}</td></tr>
			</table>
		`;
		// Location
		let gpsPrecisionLabel = getGPSPrecision(picMeta);
		let orientation = picMeta?.properties?.["view:azimuth"];
		if(orientation !== undefined && orientation !== null) { orientation = orientation.toString()+"°"; }
		else { orientation = "unknown"; }
		popupContent += `
			<h4>${fat(faLocationDot)} ${this._t.gvs.metadata_location}</h4>
			<table class="gvs-table-light">
				<tr><th scope="row">${this._t.gvs.metadata_location_longitude}</th><td>${picMeta.gps[0]}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_location_latitude}</th><td>${picMeta.gps[1]}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_location_orientation}</th><td>${orientation}</td></tr>
				<tr><th scope="row">${this._t.gvs.metadata_location_precision}</th><td>${gpsPrecisionLabel}</td></tr>
			</table>
		`;

		// EXIF tags
		if(picMeta.properties?.exif) {
			popupContent += `
				<details>
				<summary>${fat(faGear)} ${this._t.gvs.metadata_exif}</summary>
				<table>
					<thead>
						<tr>
							<th scope="col">${this._t.gvs.metadata_exif_name}</th>
							<th scope="col">${this._t.gvs.metadata_exif_value}</th>
						</tr>
					</thead>
					<tbody>
						${Object.entries(picMeta.properties.exif).map(e => (`
							<tr>
								<th scope="row">`+e[0]+`</th>
								<td>`+e[1]+`</td>
							</tr>
						`)).join("\n")}
					</tbody>
				</table>
				</details>
			`;
		}
		this._viewer.setPopup(true, popupContent);
		const event = new CustomEvent("focus-changed", { detail: { focus: "meta" } });
		this._viewer.dispatchEvent(event);
	}

	/**
	 * Creates expand/reduce mini component.
	 * This should be called only if map is enabled.
	 * @private
	 */
	_initWidgetMiniActions() {
		// Mini widget expand
		const imgExpand = document.createElement("img");
		imgExpand.src = SwitchBig;
		const lblExpand = document.createElement("span");
		lblExpand.classList.add("gvs-mobile-hidden");
		lblExpand.appendChild(document.createTextNode(this._t.gvs.expand));
		const btnExpand = createButton("gvs-mini-expand", lblExpand, this._t.gvs.expand_info, ["gvs-only-mini", "gvs-print-hidden"]);
		btnExpand.appendChild(imgExpand);
		this._corners["mini-top-right"].appendChild(btnExpand);
		btnExpand.addEventListener("click", () => {
			this._viewer.setFocus(this._viewer.isMapWide() ? "pic" : "map");
		});

		// Mini widget hide
		const imgReduce = document.createElement("img");
		imgReduce.src = SwitchMini;
		const btnHide = createButton("gvs-mini-hide", imgReduce, this._t.gvs.minimize, ["gvs-only-mini", "gvs-print-hidden"]);
		this._corners["mini-bottom-left"].appendChild(btnHide);
		btnHide.addEventListener("click", () => {
			this._viewer.setUnfocusedVisible(false);
		});

		// Mini widget show
		const btnShow = createButton("gvs-mini-show", null, null, ["gvs-btn-large", "gvs-only-mini-hidden", "gvs-print-hidden"]);
		this._corners["main-bottom-left"].appendChild(btnShow);
		btnShow.addEventListener("click", () => {
			this._viewer.setUnfocusedVisible(true);
		});

		const miniBtnRendering = () => {
			if(this._viewer.map && this._viewer.isMapWide()) {
				btnShow.title = this._t.gvs.show_psv;
				btnShow.innerHTML = fat(faPanorama);
			}
			else {
				btnShow.title = this._t.gvs.show_map;
				btnShow.innerHTML = fat(faMap);
			}
		};

		miniBtnRendering();
		this._viewer.addEventListener("focus-changed", miniBtnRendering);
	}

	/**
	 * Creates search bar component.
	 * This should be called only if map is enabled.
	 * @private
	 */
	_initWidgetSearch() {
		const geocoder = createSearchBar(
			"gvs-widget-search-bar",
			this._t.gvs.search_address,
			(query) => this._viewer.map.geocoder({
				query,
				limit: 3,
				bbox: this._viewer.map.getBounds().toArray().map(d => d.join(",")).join(","),
				proximity: this._viewer.map.getCenter().lat+","+this._viewer.map.getCenter().lng,
			}).then(data => {
				data = data.features.map(f => ({
					title: f.place_name.split(",")[0],
					subtitle: f.place_name.split(",").slice(1).join(", "),
					data: f
				}));
				return data;
			}),
			(entry) => {
				if(entry) {
					if(entry.data.bounds) {
						this._viewer.map.fitBounds(entry.data.bounds);
					}
					else {
						this._viewer.map.flyTo({
							center: entry.data.center,
							zoom: entry.data.zoom || 13,
						});
					}
				}
			},
			this,
			undefined,
			this._viewer.isWidthSmall()
		);

		if(this._viewer.isWidthSmall()) {
			geocoder.style.maxWidth = `${this._viewer.container.offsetWidth - 20}px`;
		}
		createGroup(
			"gvs-widget-search",
			this._viewer.isWidthSmall() ? "main-top-right" : "main-top-left",
			this,
			[geocoder],
			["gvs-only-map", "gvs-print-hidden"]
		);
	}

	/**
	 * Creates the map layers component.
	 * This should be called only if map is enabled.
	 * @private
	 */
	_initWidgetMapLayers() {
		const btnLayers = createExpandableButton("gvs-map-layers", faLayerGroup, this._t.gvs.layers, this);
		const pnlLayers = createPanel(this, btnLayers, []);
		createGroup(
			"gvs-widget-map-layers",
			"main-top-right",
			this,
			[btnLayers, pnlLayers],
			["gvs-group-large", "gvs-group-btnpanel", "gvs-only-map", "gvs-print-hidden"]
		);

		// Map background selector
		if(this._viewer.map.hasTwoBackgrounds()) {
			pnlLayers.innerHTML = `
				<h4>${fat(faEarthEurope)} ${this._t.gvs.map_background}</h4>
				<div id="gvs-map-bg" class="gvs-input-group">
					<input type="radio" id="gvs-map-bg-streets" name="gvs-map-bg" value="streets" />
					<label for="gvs-map-bg-streets">
						<img id="gvs-map-bg-streets-img" />
						${this._t.gvs.map_background_streets}
					</label>
					<input type="radio" id="gvs-map-bg-aerial" name="gvs-map-bg" value="aerial" />
					<label for="gvs-map-bg-aerial">
						<img id="gvs-map-bg-aerial-img" />
						${this._t.gvs.map_background_aerial}
					</label>
				</div>`;
		}

		// Map theme selector
		pnlLayers.innerHTML += `
			<h4>${fat(faPalette)} ${this._t.gvs.map_theme}</h4>
			<div class="gvs-input-group">
				<select id="gvs-map-theme" style="width: 100%;">
					<option value="default">${this._t.gvs.map_theme_default}</option>
					<option value="age">${this._t.gvs.map_theme_age}</option>
					<option value="type">${this._t.gvs.map_theme_type}</option>
				</select>
			</div>
			<div>
				<div id="gvs-map-theme-legend-age" class="gvs-map-theme-legend gvs-hidden">
					<div>
						<div class="gvs-map-theme-legend-entry">
							<span class="gvs-map-theme-color" style="background-color: ${COLORS["PALETTE_4"]}"></span>
							${this._t.gvs["map_theme_age_4"]}
						</div>
						<div class="gvs-map-theme-legend-entry">
							<span class="gvs-map-theme-color" style="background-color: ${COLORS["PALETTE_3"]}"></span>
							${this._t.gvs["map_theme_age_3"]}
						</div>
					</div>
					<div>
						<div class="gvs-map-theme-legend-entry">
							<span class="gvs-map-theme-color" style="background-color: ${COLORS["PALETTE_2"]}"></span>
							${this._t.gvs["map_theme_age_2"]}
						</div>
						<div class="gvs-map-theme-legend-entry">
							<span class="gvs-map-theme-color" style="background-color: ${COLORS["PALETTE_1"]}"></span>
							${this._t.gvs["map_theme_age_1"]}
						</div>
					</div>
				</div>
				<div id="gvs-map-theme-legend-type" class="gvs-map-theme-legend gvs-hidden">
					<div class="gvs-map-theme-legend-entry">
						<span class="gvs-map-theme-color" style="background-color: ${COLORS.QUALI_1}"></span>
						${this._t.gvs.picture_360}
					</div>
					<div class="gvs-map-theme-legend-entry">
						<span class="gvs-map-theme-color" style="background-color: ${COLORS.QUALI_2}"></span>
						${this._t.gvs.picture_flat}
					</div>
				</div>
			</div>`;

		// Map theme events
		const fMapTheme = pnlLayers.querySelector("#gvs-map-theme");
		const onChange = () => {
			this._onMapThemeChange();
			this._onMapFiltersChange();
		};
		fMapTheme.addEventListener("change", onChange);
		fMapTheme.addEventListener("keypress", onChange);
		fMapTheme.addEventListener("paste", onChange);
		fMapTheme.addEventListener("input", onChange);

		// Map background events
		if(this._viewer.map.hasTwoBackgrounds()) {
			const imgBgAerial = pnlLayers.querySelector("#gvs-map-bg-aerial-img");
			imgBgAerial.src = BackgroundAerial;
			const imgBgStreets = pnlLayers.querySelector("#gvs-map-bg-streets-img");
			imgBgStreets.src = BackgroundStreets;
			const radioBgAerial = pnlLayers.querySelector("#gvs-map-bg-aerial");
			const radioBgStreets = pnlLayers.querySelector("#gvs-map-bg-streets");
			const onBgChange = e => {
				this._viewer.map.setBackground(e.target.value);
			};
			radioBgAerial.addEventListener("change", onBgChange);
			radioBgStreets.addEventListener("change", onBgChange);
			this._viewer.addEventListener("map:background-changed", e => this._onMapBackgroundChange(e.detail.background));
			this._onMapBackgroundChange(this._viewer.map.getBackground());
		}
	}

	/**
	 * Change the selected background in radio buttons
	 * @param {string} bg The background to use
	 * @private
	 */
	_onMapBackgroundChange(bg) {
		const radioBgAerial = document.getElementById("gvs-map-bg-aerial");
		const radioBgStreets = document.getElementById("gvs-map-bg-streets");
		if(bg === "aerial") { radioBgAerial.checked = true; }
		else { radioBgStreets.checked = true; }
	}

	/**
	 * Updates map theme legend when theme changes.
	 * @private
	 */
	_onMapThemeChange() {
		const fMapTheme = document.getElementById("gvs-map-theme");
		const t = fMapTheme.value;
		for(let d of document.getElementsByClassName("gvs-map-theme-legend")) {
			if(d.id == "gvs-map-theme-legend-"+t) {
				d.classList.remove("gvs-hidden");
			}
			else {
				d.classList.add("gvs-hidden");
			}
		}
	}

	/**
	 * Creates pictures filters component.
	 * This should be called only if map is enabled.
	 * @private
	 */
	_initWidgetFilters(hasUserSearch) {
		const btnFilter = createExpandableButton("gvs-filter", faSliders, this._t.gvs.filters, this);
		const pnlFilter = createPanel(this, btnFilter, []);
		pnlFilter.innerHTML = `
			<form id="gvs-filter-form">
				<div id="gvs-filter-zoomin">${fat(faTriangleExclamation)} ${this._t.gvs.filter_zoom_in}</div>
				<h4>${fat(faCalendar)} ${this._t.gvs.filter_date}</h4>
				<div class="gvs-input-group">
					<input type="date" id="gvs-filter-date-from" />
					${fat(faArrowRight)}
					<input type="date" id="gvs-filter-date-end" />
				</div>
				<h4>${fat(faImage)} ${this._t.gvs.filter_picture}</h4>
				<div class="gvs-input-group" style="justify-content: center;">
					<input type="checkbox" id="gvs-filter-type-flat" name="flat" checked />
					<label for="gvs-filter-type-flat" style="margin-right: 20px">${this._t.gvs.picture_flat}</label>
					<input type="checkbox" id="gvs-filter-type-360" name="360" checked />
					<label for="gvs-filter-type-360">${this._t.gvs.picture_360}</label>
				</div>
				<!--h4>${fat(faCamera)} ${this._t.gvs.filter_camera_model}</h4>
				<div class="gvs-input-group" id="gvs-filter-model"></div-->
			</form>
		`;
		createGroup(
			"gvs-widget-filter",
			this._viewer.isWidthSmall() ? "main-top-right" : "main-top-left",
			this,
			[btnFilter, pnlFilter],
			["gvs-group-large", "gvs-group-btnpanel", "gvs-only-map", "gvs-print-hidden"]
		);

		if(this._viewer.isWidthSmall()) {
			pnlFilter.style.width = `${this._viewer.container.offsetWidth - 70}px`;
		}

		// Create search bar for users
		if(hasUserSearch) {
			const form = pnlFilter.querySelector("#gvs-filter-form");

			const title = document.createElement("h4");
			title.innerHTML = `${fat(faUser)} ${this._t.gvs.filter_user}`;
			form.appendChild(title);

			const input = document.createElement("div");
			input.id = "gvs-filter-user";
			input.classList.add("gvs-input-group");

			const userSearch = createSearchBar(
				"gvs-filter-search-user",
				this._t.gvs.search_user,
				q => this._viewer._api.searchUsers(q)
					.then(data => ((data || [])
						.map(f => ({
							title: f.label,
							data: f
						}))
					)),
				d => this._viewer.map.setVisibleUsers(d ? [d.data.id] : ["geovisio"]),
				this,
				true
			);
			input.appendChild(userSearch);
			form.appendChild(input);
		}

		// Create search bar for camera model
		// TODO : implement when API is ready
		// const cameraSearch = createSearchBar(
		// 	"gvs-filter-camera-model",
		// 	this._t.gvs.search,
		// 	() => Promise.reject(),
		// 	() => {},
		//  this
		// );
		// document.getElementById("gvs-filter-model").appendChild(cameraSearch);

		const form = pnlFilter.children[0];
		this._formDelay = null;

		const onFormChange = () => {
			if(this._formDelay) { clearTimeout(this._formDelay); }

			this._formDelay = setTimeout(() => {
				this._onMapFiltersChange();
			}, 250);
		};

		form.addEventListener("change", onFormChange);
		form.addEventListener("reset", onFormChange);
		form.addEventListener("submit", e => {
			onFormChange(e);
			e.preventDefault();
			return false;
		}, true);

		for(let i of form.getElementsByTagName("input")) {
			i.addEventListener("change", onFormChange);
			i.addEventListener("keypress", onFormChange);
			i.addEventListener("paste", onFormChange);
			i.addEventListener("input", onFormChange);
		}
	}

	/**
	 * Send viewer new map filters values.
	 * @private
	 */
	_onMapFiltersChange() {
		const fMinDate = document.getElementById("gvs-filter-date-from");
		const fMaxDate = document.getElementById("gvs-filter-date-end");
		const fTypeFlat = document.getElementById("gvs-filter-type-flat");
		const fType360 = document.getElementById("gvs-filter-type-360");
		// const fCamera = document.getElementById("gvs-filter-camera");
		const fMapTheme = document.getElementById("gvs-map-theme");

		let type = "";
		if(fType360.checked && !fTypeFlat.checked) { type = "equirectangular"; }
		if(!fType360.checked && fTypeFlat.checked) { type = "flat"; }

		const values = {
			minDate: fMinDate.value,
			maxDate: fMaxDate.value,
			type,
			// camera: fCamera.value,
			theme: fMapTheme.value,
		};

		this._viewer.setFilters(values);
	}

	/**
	 * Listen to viewer events to follow map filters changes.
	 * @private
	 */
	_listenMapFiltersChanges() {
		const fMinDate = document.getElementById("gvs-filter-date-from");
		const fMaxDate = document.getElementById("gvs-filter-date-end");
		const fTypeFlat = document.getElementById("gvs-filter-type-flat");
		const fType360 = document.getElementById("gvs-filter-type-360");
		// const fCamera = document.getElementById("gvs-filter-camera");
		const fMapTheme = document.getElementById("gvs-map-theme");

		// Update widget based on programmatic filter changes
		this._viewer.addEventListener("filters-changed", e => {
			if(e.detail.minDate) { fMinDate.value = e.detail.minDate; }
			if(e.detail.maxDate) { fMaxDate.value = e.detail.maxDate; }
			// if(e.detail.camera) { fCamera.value = e.detail.camera; }
			if(e.detail.theme) { fMapTheme.value = e.detail.theme; }
			if(e.detail.type) {
				fType360.checked = ["", "equirectangular"].includes(e.detail.type);
				fTypeFlat.checked = ["", "flat"].includes(e.detail.type);
			}
			this._onMapThemeChange();
		});

		// Show/hide zoom in warning when map zoom changes
		const lblZoomIn = document.getElementById("gvs-filter-zoomin");
		const changeLblZoomInDisplay = () => {
			if(this._viewer.map.getZoom() < 7) { lblZoomIn.style.display = null; }
			else { lblZoomIn.style.display = "none"; }
		};
		changeLblZoomInDisplay();
		this._viewer.map.on("zoomend", changeLblZoomInDisplay);
	}

	/**
	 * Creates share map/picture widget.
	 * @private
	 */
	_initWidgetShare() {
		const btnShare = createButton("gvs-share", fa(faShareNodes), this._t.gvs.share, ["gvs-btn-large"]);
		const pnlShare = createPanel(this, btnShare, []);
		pnlShare.innerHTML = `
			<div class="gvs-hidden">
				<p id="gvs-share-license" style="margin: 0 0 10px 0;"></p>
			</div>
			<h4 style="margin-top: 0">${fat(faLink)} ${this._t.gvs.share_links}</h4>
			<div id="gvs-share-links" class="gvs-input-btn">
				<a id="gvs-share-image" class="gvs-link-btn gvs-hidden" download target="_blank">${fat(faCloudArrowDown)} ${this._t.gvs.share_image}</a>
				<button id="gvs-share-url" data-copy="true" style="flex-basis: 100%; flex-grow: 2; flex-shrink: 2;">${fat(faCopy)} ${this._t.gvs.share_page}</button>
				<button id="gvs-share-print" style="flex-basis: 100%; flex-grow: 2; flex-shrink: 2;">${fat(faPrint)} ${this._t.gvs.share_print}</button>
			</div>
			<h4>${fat(faMap)} ${this._t.gvs.share_embed}</h4>
			<div class="gvs-input-btn">
				<textarea id="gvs-share-iframe" readonly></textarea>
				<button data-input="gvs-share-iframe">${fat(faCopy)} ${this._t.gvs.copy}</button>
			</div>
			<h4 class="gvs-hidden">${fat(faPen)} ${this._t.gvs.edit_osm}</h4>
			<div class="gvs-input-btn gvs-hidden" style="justify-content: center">
				<a id="gvs-edit-id" class="gvs-link-btn" target="_blank">${fat(faLocationDot)} ${this._t.gvs.id}</a>
				<button id="gvs-edit-josm" title="${this._t.gvs.josm_live}">${fat(faSatelliteDish)} ${this._t.gvs.josm}</button>
			</div>
		`;
		createGroup(
			"gvs-widget-share",
			"main-bottom-right",
			this,
			[btnShare, pnlShare],
			["gvs-group-large", "gvs-group-btnpanel", "gvs-mobile-hidden", "gvs-print-hidden"]
		);

		const grpLinks = document.getElementById("gvs-share-links");
		const hdLink = document.getElementById("gvs-share-image");
		const pageLink = document.getElementById("gvs-share-url");

		// Add RSS link if available
		if(this._viewer._api.getRSSURL()) {
			const btnRss = document.createElement("a");
			btnRss.id = "gvs-share-rss";
			btnRss.classList.add("gvs-link-btn");
			btnRss.setAttribute("target", "_blank");
			btnRss.setAttribute("title", this._t.gvs.share_rss_title);
			btnRss.appendChild(fa(faSquareRss));
			btnRss.appendChild(document.createTextNode(this._t.gvs.share_rss));
			grpLinks.insertBefore(btnRss, pageLink);
		}

		// Update picture download links
		this._viewer.addEventListener("psv:picture-loaded", () => {
			const picMeta = this._viewer.psv.getPictureMetadata();
			hdLink.href = picMeta.panorama.hdUrl;

			const lblLicense = document.getElementById("gvs-share-license");
			lblLicense.innerHTML = picMeta?.caption?.license ? this._t.gvs.legend_license.replace("{l}", picMeta.caption.license) : "";

			while(pnlShare.getElementsByClassName("gvs-hidden").length > 0) {
				const h = pnlShare.getElementsByClassName("gvs-hidden")[0];
				h.classList.remove("gvs-hidden");
			}
		});

		// Update links
		const updateLinks = e => {
			const baseUrl = e?.detail?.url || window.location.href.replace(/\/$/, "");
			const fUrl = pnlShare.querySelector("#gvs-share-url");
			const fIframe = pnlShare.querySelector("#gvs-share-iframe");
			const btnId = pnlShare.querySelector("#gvs-edit-id");
			const btnRss = pnlShare.querySelector("#gvs-share-rss");

			fUrl.setAttribute("data-copy", baseUrl);
			fIframe.innerText = `<iframe src="${baseUrl}" style="border: none; width: 500px; height: 300px"></iframe>`;

			const meta = this._viewer.psv.getPictureMetadata();
			if(meta) {
				btnId.setAttribute("href", `${this._options.editIdUrl}#map=19/${meta.gps[1]}/${meta.gps[0]}&source=Panoramax`);
			}

			if(btnRss) {
				btnRss.setAttribute("href", this._viewer._api.getRSSURL(this._viewer?.map?.getBounds()));
			}
		};
		
		updateLinks();
		this._viewer?._hash?.addEventListener("url-changed", updateLinks);

		// Copy to clipboard on button click
		enableCopyButton(pnlShare, this._viewer._t);

		// JOSM live edit button
		const btnJosm = pnlShare.querySelector("#gvs-edit-josm");
		btnJosm.addEventListener("click", () => {
			// Disable
			if(btnJosm.classList.contains("gvs-btn-active")) {
				this._viewer.toggleJOSMLive(false);
			}
			// Enable
			else {
				this._viewer.toggleJOSMLive(true).catch(e => {
					console.warn(e);
					alert(this._t.gvs.error_josm);
				});
			}
		});
		this._viewer.addEventListener("josm-live-enabled", () => btnJosm.classList.add("gvs-btn-active"));
		this._viewer.addEventListener("josm-live-disabled", () => btnJosm.classList.remove("gvs-btn-active"));

		// Print button
		const printLink = pnlShare.querySelector("#gvs-share-print");
		printLink.addEventListener("click", window.print.bind(window));
	}
}
