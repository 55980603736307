import { getSphereCorrection } from "./Exif";

import ArrowTriangleSVG from "../img/arrow_triangle.svg";
import ArrowTurnSVG from "../img/arrow_turn.svg";

export const COLORS = {
	BASE: "#FF6F00",
	SELECTED: "#1E88E5",
	HIDDEN: "#34495E",
	NEXT: "#ffab40",

	QUALI_1: "#00695C", // 360
	QUALI_2: "#fd8d3c", // flat

	PALETTE_1: "#fecc5c", // Oldest
	PALETTE_2: "#fd8d3c",
	PALETTE_3: "#f03b20",
	PALETTE_4: "#bd0026" // Newest
};

export const COLORS_HEX = Object.fromEntries(Object.entries(COLORS).map(e => {
	e[1] = parseInt(e[1].slice(1), 16);
	return e;
}));

const ArrowTriangle = svgToPSVLink(ArrowTriangleSVG, "white");
const ArrowTurn = svgToPSVLink(ArrowTurnSVG, COLORS.NEXT);

/**
 * Get cartesian distance between two points
 * @param {number[]} from Start [x,y] coordinates
 * @param {number[]} to End [x,y] coordinates
 * @returns {number} The distance
 * @private
 */
export function getDistance(from, to) {
	const dx = from[0] - to[0];
	const dy = from[1] - to[1];
	return Math.sqrt(dx*dx + dy*dy);
}

/**
 * Compare function to retrieve most appropriate picture in a single direction.
 * 
 * @param {number[]} picPos The picture [x,y] position
 * @returns {function} A compare function for sorting
 * @private
 */
export function sortPicturesInDirection(picPos) {
	return (a,b) => {
		// Two prev/next links = no sort
		if(a.rel != "related" && b.rel != "related") { return 0; }
		// First is prev/next link = goes first
		else if(a.rel != "related") { return -1; }
		// Second is prev/next link = goes first
		else if(b.rel != "related") { return 1; }
		// Two related links same day = nearest goes first
		else if(a.date == b.date) { return getDistance(picPos, a.geometry.coordinates) - getDistance(picPos, b.geometry.coordinates); }
		// Two related links at different day = recent goes first
		else { return b.date.localeCompare(a.date); }
	};
}

/**
 * Transforms a Base64 SVG string into a DOM img element.
 * @param {string} svg The SVG as Base64 string
 * @returns {Element} The DOM image element
 * @private
 */
function svgToPSVLink(svg, fillColor) {
	try {
		const svgStr = atob(svg.replace(/^data:image\/svg\+xml;base64,/, ""));
		const svgXml = (new DOMParser()).parseFromString(svgStr, "image/svg+xml").childNodes[0];
		const btn = document.createElement("button");
		btn.appendChild(svgXml);
		btn.classList.add("gvs-psv-tour-arrows");//"psv-virtual-tour-arrow", "psv-virtual-tour-link");
		btn.style.color = fillColor;
		return btn;
	}
	catch(e) {
		const img = document.createElement("img");
		img.src = svg;
		return img;
	}
}

/**
 * Clones a model PSV link
 * @private
 */
function getArrow(a) {
	const d = a.cloneNode(true);
	d.addEventListener("pointerup", () => d.classList.add("gvs-clicked"));
	return d;
}

/**
 * Get direction based on angle
 * @param {number[]} from Start [x,y] coordinates
 * @param {number[]} to End [x,y] coordinates
 * @returns {number} The azimuth, from 0 to 360°
 * @private
 */
export function getAzimuth(from, to) {
	return (Math.atan2(to[0] - from[0], to[1] - from[1]) * (180 / Math.PI) + 360) % 360;
}

/**
 * Computes relative heading for a single picture, based on its metadata
 * @param {*} m The picture metadata
 * @returns {number} The relative heading
 * @private
 */
export function getRelativeHeading(m) {
	if(!m) { throw new Error("No picture selected"); }

	let prevSegDir, nextSegDir;
	const currHeading = m.properties["view:azimuth"];

	// Previous picture GPS coordinates
	if(m?.sequence?.prevPic) {
		const prevLink = m?.links?.find(l => l.nodeId === m.sequence.prevPic);
		if(prevLink) {
			prevSegDir = (((currHeading - getAzimuth(prevLink.gps, m.gps)) + 180) % 360) - 180;
		}
	}

	// Next picture GPS coordinates
	if(m?.sequence?.nextPic) {
		const nextLink = m?.links?.find(l => l.nodeId === m.sequence.nextPic);
		if(nextLink) {
			nextSegDir = (((currHeading - getAzimuth(m.gps, nextLink.gps)) + 180) % 360) - 180;
		}
	}

	return prevSegDir !== undefined ? prevSegDir : (nextSegDir !== undefined ? nextSegDir : 0);
}

/**
 * Get direction based on angle
 * @param {number[]} from Start [x,y] coordinates
 * @param {number[]} to End [x,y] coordinates
 * @returns {string} Direction (N/ENE/ESE/S/WSW/WNW)
 * @private
 */
export function getSimplifiedAngle(from, to) {
	const angle = Math.atan2(to[0] - from[0], to[1] - from[1]) * (180 / Math.PI); // -180 to 180°

	// 6 directions version
	if (Math.abs(angle) < 30) { return "N"; }
	else if (angle >= 30 && angle < 90) { return "ENE"; }
	else if (angle >= 90 && angle < 150) { return "ESE"; }
	else if (Math.abs(angle) >= 150) { return "S"; }
	else if (angle <= -30 && angle > -90) { return "WNW"; }
	else if (angle <= -90 && angle > -150) { return "WSW"; }
}

/**
 * Converts result from getPosition or position-updated event into x/y/z coordinates
 *
 * @param {object} pos pitch/yaw as given by PSV
 * @param {number} zoom zoom as given by PSV
 * @returns {object} Coordinates as x/y in degrees and zoom as given by PSV
 * @private
 */
export function positionToXYZ(pos, zoom = undefined) {
	const res = {
		x: pos.yaw * (180/Math.PI),
		y: pos.pitch * (180/Math.PI)
	};

	if(zoom) { res.z = zoom; }
	return res;
}

/**
 * Converts x/y/z coordinates into PSV position (lat/lon/zoom)
 *
 * @param {number} x The X coordinate (in degrees)
 * @param {number} y The Y coordinate (in degrees)
 * @param {number} z The zoom level (0-100)
 * @returns {object} Position coordinates as yaw/pitch/zoom
 * @private
 */
export function xyzToPosition(x, y, z) {
	return {
		yaw: x / (180/Math.PI),
		pitch: y / (180/Math.PI),
		zoom: z
	};
}

/**
 * Generates the navbar caption based on a single picture metadata
 *
 * @param {object} metadata The picture metadata
 * @param {object} t The labels translations container
 * @returns {object} Normalized object with user name, licence and date
 * @private
 */
export function getNodeCaption(metadata, t) {
	const caption = {};

	// Timestamp
	if(metadata?.properties?.datetimetz) {
		caption.date = new Date(metadata.properties.datetimetz);
	}
	else if(metadata?.properties?.datetime) {
		caption.date = new Date(metadata.properties.datetime);
	}

	// Producer
	if(metadata?.providers) {
		const producerRoles = metadata?.providers?.filter(el => el?.roles?.includes("producer"));
		if(producerRoles?.length >= 0) {
			// Avoid duplicates between account name and picture author
			const producersDeduped = {};
			producerRoles.map(p => p.name).forEach(p => {
				const pmin = p.toLowerCase().replace(/\s/g, "");
				if(producersDeduped[pmin]) { producersDeduped[pmin].push(p); }
				else { producersDeduped[pmin] = [p];}
			});

			// Keep best looking name for each
			caption.producer = [];
			Object.values(producersDeduped).forEach(pv => {
				const deflt = pv[0];
				const better = pv.find(v => v.toLowerCase() != v);
				caption.producer.push(better || deflt);
			});
			caption.producer = caption.producer.join(", ");
		}
	}

	// License
	if(metadata?.properties?.license) {
		caption.license = metadata.properties.license;
		// Look for URL to license
		if(metadata?.links) {
			const licenseLink = metadata.links.find(l => l?.rel === "license");
			if(licenseLink) {
				caption.license = `<a href="${licenseLink.href}" title="${t.gvs.metadata_general_license_link}" target="_blank">${caption.license}</a>`;
			}
		}
	}

	return caption;
}

/**
 * Creates links between map and photo elements.
 * This enable interactions like click on map showing picture.
 * 
 * @param {CoreView} parent The view containing both Photo and Map elements
 * @private
 */
export function linkMapAndPhoto(parent) {
	// Switched picture
	const onPicLoad = e => parent.map.displayPictureMarker(e.detail.lon, e.detail.lat, e.detail.x);
	parent.addEventListener("psv:picture-loading", onPicLoad);
	parent.addEventListener("psv:picture-loaded", onPicLoad);

	// Picture view rotated
	parent.addEventListener("psv:view-rotated", () => {
		const x = parent.psv.getPosition().yaw * (180 / Math.PI);
		parent.map._picMarker.setRotation(x);
	});

	// Picture preview
	parent.addEventListener("psv:picture-preview-started", e => {
		// Show marker corresponding to selection
		parent.map._picMarkerPreview
			.setLngLat(e.detail.coordinates)
			.setRotation(e.detail.direction || 0)
			.addTo(parent.map);
	});

	parent.addEventListener("psv:picture-preview-stopped", () => {
		parent.map._picMarkerPreview.remove();
	});

	parent.addEventListener("psv:picture-loaded", e => {
		if (parent.isWidthSmall() && parent._picPopup && e.detail.picId == parent._picPopup._picId) {
			parent._picPopup.remove();
		}
	});

	// Picture click
	parent.addEventListener("map:picture-click", e => {
		parent.select(e.detail.seqId, e.detail.picId);
		if(!parent.psv._myVTour.state.currentNode && parent?.setFocus) { parent.setFocus("pic"); }
	});

	// Sequence click
	parent.addEventListener("map:sequence-click", e => {
		parent._api.getPicturesAroundCoordinates(
			e.detail.coordinates.lat,
			e.detail.coordinates.lng,
			1,
			1,
			e.detail.seqId
		).then(results => {
			if(results?.features?.length > 0) {
				parent.select(results.features[0]?.collection, results.features[0].id);
				if(!parent.psv.getPictureMetadata() && parent?.setFocus) { parent.setFocus("pic"); }
			}
		});
	});
}

/**
 * Transforms a GeoJSON feature from the STAC API into a PSV node.
 * 
 * @param {object} f The API GeoJSON feature
 * @param {object} t The labels translations container
 * @return {object} A PSV node
 * @private
 */
export function apiFeatureToPSVNode(f, t) {
	const isHorizontalFovDefined = f.properties?.["pers:interior_orientation"]?.["field_of_view"] != null;
	let horizontalFov = isHorizontalFovDefined ? parseInt(f.properties["pers:interior_orientation"]["field_of_view"]) : 70;
	const is360 = horizontalFov === 360;

	const hdUrl = (Object.values(f.assets).find(a => a?.roles?.includes("data")) || {}).href;
	const matrix = f?.properties?.["tiles:tile_matrix_sets"]?.geovisio;
	const prev = f.links.find(l => l?.rel === "prev" && l?.type === "application/geo+json");
	const next = f.links.find(l => l?.rel === "next" && l?.type === "application/geo+json");
	const baseUrlWebp = Object.values(f.assets).find(a => a.roles?.includes("visual") && a.type === "image/webp");
	const baseUrlJpeg = Object.values(f.assets).find(a => a.roles?.includes("visual") && a.type === "image/jpeg");
	const baseUrl = (baseUrlWebp || baseUrlJpeg).href;
	const thumbUrl = (Object.values(f.assets).find(a => a.roles?.includes("thumbnail") && a.type === "image/jpeg"))?.href;
	const tileUrl = f?.asset_templates?.tiles_webp || f?.asset_templates?.tiles;

	return {
		id: f.id,
		caption: getNodeCaption(f, t),
		panorama: is360 ? {
			baseUrl,
			origBaseUrl: baseUrl,
			basePanoData: (img) => ({
				fullWidth: img.width,
				fullHeight: img.height,
				poseHeading: 0,
				posePitch: 0,
				poseRoll: 0,
			}),
			hdUrl,
			thumbUrl,
			cols: matrix && matrix.tileMatrix[0].matrixWidth,
			rows: matrix && matrix.tileMatrix[0].matrixHeight,
			width: matrix && (matrix.tileMatrix[0].matrixWidth * matrix.tileMatrix[0].tileWidth),
			tileUrl: matrix && ((col, row) => tileUrl.href.replace(/\{TileCol\}/g, col).replace(/\{TileRow\}/g, row))
		} : {
			// Flat pictures are shown only using a cropped base panorama
			baseUrl,
			origBaseUrl: baseUrl,
			hdUrl,
			thumbUrl,
			basePanoData: (img) => {
				if (img.width < img.height && !isHorizontalFovDefined) {
					horizontalFov = 35;
				}
				const verticalFov = horizontalFov * img.height / img.width;
				const panoWidth = img.width * 360 / horizontalFov;
				const panoHeight = img.height * 180 / verticalFov;

				return {
					fullWidth: panoWidth,
					fullHeight: panoHeight,
					croppedWidth: img.width,
					croppedHeight: img.height,
					croppedX: (panoWidth - img.width) / 2,
					croppedY: (panoHeight - img.height) / 2,
					poseHeading: 0,
					posePitch: 0,
					poseRoll: 0,
				};
			},
			// This is only to mock loading of tiles (which are not available for flat pictures)
			cols: 2, rows: 1, width: 2, tileUrl: () => null
		},
		links: filterRelatedPicsLinks(f),
		gps: f.geometry.coordinates,
		sequence: {
			id: f.collection,
			nextPic: next ? next.id : undefined,
			prevPic: prev ? prev.id : undefined
		},
		sphereCorrection: getSphereCorrection(f),
		horizontalFov,
		properties: f.properties,
	};
}

/**
 * Filter surrounding pictures links to avoid too much arrows on viewer.
 * @private
 */
export function filterRelatedPicsLinks(metadata) {
	const picLinks = metadata.links
		.filter(l => ["next", "prev", "related"].includes(l?.rel) && l?.type === "application/geo+json")
		.map(l => {
			if(l.datetime) {
				l.date = l.datetime.split("T")[0];
			}
			return l;
		});
	const picPos = metadata.geometry.coordinates;

	// Filter to keep a single link per direction, in same sequence or most recent one
	const filteredLinks = [];
	const picSurroundings = { "N": [], "ENE": [], "ESE": [], "S": [], "WSW": [], "WNW": [] };

	for(let picLink of picLinks) {
		const a = getSimplifiedAngle(picPos, picLink.geometry.coordinates);
		picSurroundings[a].push(picLink);
	}

	for(let direction in picSurroundings) {
		const picsInDirection = picSurroundings[direction];
		if(picsInDirection.length == 0) { continue; }
		picsInDirection.sort(sortPicturesInDirection(picPos));
		filteredLinks.push(picsInDirection.shift());
	}

	let arrowStyle = l => l.rel === "related" ? {
		element: getArrow(ArrowTurn),
		size: { width: 64*2/3, height: 192*2/3 }
	} : {
		element: getArrow(ArrowTriangle),
		size: { width: 75, height: 75 }
	};

	return filteredLinks.map(l => ({
		nodeId: l.id,
		gps: l.geometry.coordinates,
		arrowStyle: arrowStyle(l),
	}));
}

/**
 * Get the query string for JOSM to load current picture area
 * @returns {string} The query string, or null if not available
 * @private
 */
export function josmBboxParameters(meta) {
	if(meta) {
		const coords = meta.gps;
		const heading = typeof meta?.sphereCorrection?.pan === "number" ? - meta.sphereCorrection.pan / (Math.PI / 180) : null;
		const delta = 0.0002;
		const values = {
			left: coords[0] - (heading === null || heading >= 180 ? delta : 0),
			right: coords[0] + (heading === null || heading <= 180 ? delta : 0),
			top: coords[1] + (heading === null || heading <= 90 || heading >= 270 ? delta : 0),
			bottom: coords[1] - (heading === null || (heading >= 90 && heading <= 270) ? delta : 0),
			changeset_source: "Panoramax"
		};
		return Object.entries(values).map(e => e.join("=")).join("&");
	}
	else { return null; }
}
