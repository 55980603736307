/**
 * Read float value from EXIF tags (to handle fractions & all)
 * @param {*} val The input EXIF tag value
 * @returns {number|undefined} The parsed value, or undefined if value is not readable
 * @private
 */
export function getExifFloat(val) {
	// Null-like values
	if(
		[null, undefined, ""].includes(val)
		|| typeof val === "string" && val.trim() === ""
	) {
		return undefined;
	}
	// Already valid number
	else if(typeof val === "number") {
		return val;
	}
	// String
	else if(typeof val === "string") {
		// Check if looks like a fraction
		if(/^-?\d+(\.\d+)?\/-?\d+(\.\d+)?$/.test(val)) {
			const parts = val.split("/").map(p => parseFloat(p));
			return parts[0] / parts[1];
		}

		// Try a direct cast to float
		try { return parseFloat(val); }
		catch(e) {} // eslint-disable-line no-empty

		// Unrecognized
		return undefined;
	}
	else { return undefined; }
}

/**
 * Find in picture metadata the GPS precision.
 * @param {object} picture The GeoJSON picture feature
 * @returns {string} The precision value (poor, fair, moderate, good, excellent, ideal, unknown)
 * @private
 */
export function getGPSPrecision(picture) {
	let quality = "unknown";
	const gpsHPosError = getExifFloat(picture?.properties?.exif?.["Exif.GPSInfo.GPSHPositioningError"]);
	const gpsDop = getExifFloat(picture?.properties?.exif?.["Exif.GPSInfo.GPSDOP"]);
	
	if(gpsHPosError !== undefined) {
		if(gpsHPosError < 0.5) { quality = "ideal"; }
		else if(gpsHPosError < 1) { quality = "excellent"; }
		else if(gpsHPosError < 3) { quality = "good"; }
		else if(gpsHPosError < 7) { quality = "moderate"; }
		else if(gpsHPosError < 10) { quality = "fair"; }
		else { quality = "poor"; }
	}
	else if(gpsDop !== undefined) {
		if(gpsDop < 1) { quality = "ideal"; }
		else if(gpsDop < 2) { quality = "excellent"; }
		else if(gpsDop < 5) { quality = "good"; }
		else if(gpsDop < 10) { quality = "moderate"; }
		else if(gpsDop < 20) { quality = "fair"; }
		else { quality = "poor"; }
	}

	return quality;
}

/**
 * Compute PSV sphere correction based on picture metadata & EXIF tags.
 * @param {object} picture The GeoJSON picture feature
 * @returns {object} The PSV sphereCorrection value
 * @private
 */
export function getSphereCorrection(picture) {
	let heading = picture.properties?.["view:azimuth"];
	let pitch = picture.properties?.["pers:pitch"];
	let roll = picture.properties?.["pers:roll"];

	// Try fallbacks
	heading = heading || 0;
	const exifFallbacks = ["Xmp.Camera.$$", "Exif.GPSInfo.GPS$$", "Xmp.GPano.Pose$$Degrees"];
	if(pitch === undefined) {
		for(let exif of exifFallbacks) {
			const v = getExifFloat(picture.properties?.exif?.[exif.replace("$$", "Pitch")]);
			if(v !== undefined) {
				pitch = v;
				break;
			}
		}
	}
	pitch = pitch || 0;
	if(roll === undefined) {
		for(let exif of exifFallbacks) {
			const v = getExifFloat(picture.properties?.exif?.[exif.replace("$$", "Roll")]);
			if(v !== undefined) {
				roll = v;
				break;
			}
		}
	}
	roll = roll || 0;

	// Send result
	return {
		pan: - heading * (Math.PI / 180),
		tilt: - pitch * (Math.PI / 180),
		roll: - roll * (Math.PI / 180),
	};
}